@charset "utf-8";
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: bottom;
  border: 0;
  outline: 0;
  background: transparent; }

body {
  line-height: 1; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

nav ul {
  list-style: none; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

a {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: transparent; }

/* change colours to suit your needs */
ins {
  text-decoration: none;
  color: #000;
  background-color: #ff9; }

/* change colours to suit your needs */
mark {
  font-weight: bold;
  font-style: italic;
  color: #000;
  background-color: #ff9; }

del {
  text-decoration: line-through; }

abbr[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted; }

table {
  border-spacing: 0;
  border-collapse: collapse; }

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
  border: 0;
  border-top: 1px solid #cecece; }

input,
select {
  vertical-align: middle; }

ul,
ol {
  list-style-type: none; }

input[type='submit'],
input[type='button'] {
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button; }

input[type='submit']::-webkit-search-decoration,
input[type='button']::-webkit-search-decoration {
  display: none; }

input[type='submit']::focus,
input[type='button']::focus {
  outline-offset: -2px; }

//reset

//ベースフォントサイズ
$baseFontSize: 16;
//スマホPSDの横幅
$SmartphoneSize: 750;

//ベース
$widthBase: 1200px;

//メインコンテンツ
$widthMain: 800px;

//サイドナビ
$widthSide: 360px;

//ベースフォント
$fontBase: "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic Pro," メイリオ", Meiryo, Osaka," ＭＳ Ｐゴシック", MS PGothic," sans-serif";

//サブフォント
$subFont: "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;

//rem() ※px指定
@function rem($pixels,$context:$baseFontSize)
{
	@return $pixels / $context * 1rem;
}

//vw()
@function vw($size) {
	@return (($size / $SmartphoneSize) * 100) + vw;
}

//@include liBottom();
@mixin liBottom($px:10,$borderColor:#000)
{
  border-bottom: 1px solid $borderColor;
  margin-bottom: $px + px;
  padding-bottom: $px + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    margin-bottom: vw($px * 1.5);
    padding-bottom: vw($px * 1.5);
  }
}

//@include link-color();
@mixin link-color($color:blue) {
  color: $color;

  &:hover, &:active, &:focus {
      color: lighten($color, 20%);
    }
}





//setting ----------------------------------------------------

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

body {
  font-size: $baseFontSize + px;
  @media only screen and (max-width:$SmartphoneSize + px){
    font-size: vw($baseFontSize * 1.8);
  }

  line-height: 1.7;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans Japanese', serif;
}

img {
  border: none;
}

.clear {
  clear: both;
  height: 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

ul, ol {
  list-style: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
}

.tleft {
  text-align: left;
}

.tright {
  text-align: right;
}

.pc {
	display: block;
	@media only screen and (max-width:$SmartphoneSize + px){
		display: none;
	}
}
.sp {
	display: none;
	@media only screen and (max-width:$SmartphoneSize + px){
		display: block;
	}
}
.mt5 {
	margin-top: 5px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(5);
	}
}
.mb5 {
	margin-bottom: 5px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(5);
	}
}
.ml5 {
	margin-left: 5px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(5);
	}
}
.mr5 {
	margin-right: 5px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(5);
	}
}
.mt10 {
	margin-top: 10px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(10);
	}
}
.mb10 {
	margin-bottom: 10px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(10);
	}
}
.ml10 {
	margin-left: 10px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(10);
	}
}
.mr10 {
	margin-right: 10px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(10);
	}
}
.mt15 {
	margin-top: 15px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(15);
	}
}
.mb15 {
	margin-bottom: 15px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(15);
	}
}
.ml15 {
	margin-left: 15px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(15);
	}
}
.mr15 {
	margin-right: 15px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(15);
	}
}
.mt20 {
	margin-top: 20px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(20);
	}
}
.mb20 {
	margin-bottom: 20px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(20);
	}
}
.ml20 {
	margin-left: 20px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(20);
	}
}
.mr20 {
	margin-right: 20px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(20);
	}
}
.mt30 {
	margin-top: 30px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(30);
	}
}
.mb30 {
	margin-bottom: 30px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(30);
	}
}
.ml30 {
	margin-left: 30px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(30);
	}
}
.mr30 {
	margin-right: 30px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(30);
	}
}
.mt40 {
	margin-top: 40px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-top: vw(40);
	}
}
.mb40 {
	margin-bottom: 40px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(40);
	}
}
.ml40 {
	margin-left: 40px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-left: vw(40);
	}
}
.mr40 {
	margin-right: 40px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-right: vw(40);
	}
}

.mb50 {
	margin-bottom: 50px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(50);
	}
}

.mb60 {
	margin-bottom: 60px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(60);
	}
}

.mb70 {
	margin-bottom: 70px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(70);
	}
}

.mb80 {
	margin-bottom: 80px;
	@media only screen and (max-width:$SmartphoneSize + px){
		margin-bottom: vw(80);
	}
}

@media only screen and (min-width:$SmartphoneSize + px){
  body{
    width: 750px;
    margin: 0 auto;
  }
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-ExtraLight.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Regular.otf) format('opentype');
 }
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Medium.otf) format('opentype');
 }
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-SemiBold.otf) format('opentype');
 }
@font-face {
   font-family: 'Noto Serif Japanese';
   font-style: normal;
   font-weight: 700;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Bold.otf) format('opentype');
 }
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSerifCJKjp/NotoSerifCJKjp-Black.otf) format('opentype');
 }


img{
  max-width: 100%;
}

a:hover img {
  opacity: 0.6;
  filter: alpha(opacity=60);
  -ms-filter: "alpha( opacity=60 )";
  background: none !important;
}

a{
  @include link-color();
}

.inner{
  @media only screen and (max-width:$SmartphoneSize + px){
    width: 100%;
  }
}

header{
  background: #fff;
  .inner{
    padding: 15px 30px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap:wrap;
    -webkit-align-items: center;
    align-items: center;
    h1{
      width: 248px;
      img{
        width: 100%;
      }
    }
    ul{
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      align-items: stretch;
      li{
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        align-items: center;
        margin-right: 16px;
        img{
          width: 100%;
        }
        &:nth-child(1){
          width: 57px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: vw(57);
          }
        }
        &:nth-child(2){
          width: 54px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: vw(54);
          }
        }
        &:nth-child(3){
          width: 51px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: vw(51);
          }
          margin-right: 0;
        }
      }
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: vw(15) vw(30);
      h1{
        width: vw(248);
        img{
          width: 100%;
        }
      }
    }
  }
}

nav{
  ul{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap:wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    li{
      @media only screen and (max-width:$SmartphoneSize + px){
        width: 33.3333%;
      }
      a{
        padding: 30px 0;
        background: #000;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        align-items: center;
        box-sizing: border-box;
        text-decoration: none;
        color: #fff;
        @media only screen and (max-width:$SmartphoneSize + px){
          padding: vw(30) 0;
        }
        &:hover{
          color: #fff;
        }
        &:nth-child(1){
          width: 250px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: 100%;
          }
        }
        &:nth-child(2){
          width: 248px;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: 100%;
          }
        }
        &:nth-child(3){
          width: 100%;
          @media only screen and (max-width:$SmartphoneSize + px){
            width: vw(250);
          }
        }
        div{
          text-align: center;
          width: 100%;
          border-right: 1px solid #fff;
          p{
            line-height: 1.3;
            &:first-child{
              font-family: 'Montserrat', sans-serif;
              color: #d70c19;
              font-size: rem(24);
              @media only screen and (max-width:$SmartphoneSize + px){
                font-size: vw(24);
              }
            }
            &:last-child{
              font-size: rem(32);
              @media only screen and (max-width:$SmartphoneSize + px){
                font-size: vw(32);
              }
            }
          }
        }
      }
      &:last-child{
        div{
          border-right: none;
        }
      }
    }
  }
}

#storeTime{
  padding: 40px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(40) 0;
  }
  h2{
    text-align: center;
    span{
      padding: 10px 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      font-size: rem(32);
      color: #fff;
      background: #000;
      line-height: 1.1;
      margin-bottom: 30px;
      display: inline-block;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding: vw(15) vw(50);
        font-size: vw(32);
        margin-bottom: vw(30);
      }
    }
  }
  ul{
    padding: 0 80px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: 0 vw(50);
    }
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap:wrap;
    -webkit-align-items: center;
    align-items: center;
    li{
      font-family: 'Montserrat', sans-serif;
      font-size: rem(42);
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(42);
      }
      color: #d70c19;
      font-weight: bold;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-flex-wrap: wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      align-items: center;
      p{
        &:first-child{
          margin-right: 10px;
          @media only screen and (max-width:$SmartphoneSize + px){
            margin-right: vw(10);
          }
        }
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-flex-wrap: wrap;
        flex-wrap:wrap;
        -webkit-align-items: center;
        align-items: center;
      }
      img{
        width: 43px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(43);
        }
      }
    }
  }
  .center{
    font-size: rem(28);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(28);
    }
  }
  .shopClose{
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
    .regularHoliday
    {
      display: inline-block;
      padding: 0 20px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding: 0 vw(20);
        padding-bottom: vw(10);
        margin-bottom: vw(10);
      }
      border-bottom: 1px solid #000;
      span{
        color: #d70c19;
      }
      img{
        width: 43px;
        margin-right: 10px;
        transform: rotate(-10deg);
        -moz-transform: rotate(-10deg);
        -webkit-transform: rotate(-10deg);
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(43);
          margin-right: vw(10);
        }
      }
    }
    .Holiday{
      span{
        color: #d70c19;
        @media only screen and (max-width:600px){
          display: block;
        }
      }
    }
    font-size: rem(28);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(28);
    }
  }
}

#information{
  padding: 80px 50px;
  background: #000;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(80) vw(50);
  }
  h2{
    text-align: center;
    color: #fff;
    font-size: rem(38);
    margin-bottom: 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(70);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
  ul{
    li{
      margin-bottom: 50px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom: vw(50);
      }
      &:last-child{
        margin-bottom: 0;
      }
      p{
        &:first-child{
          color: #888;
          font-size: rem(28);
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(28);
          }
        }
        &:last-child{
          color: #fff;
          font-size: rem(32);
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(32);
          }
        }
      }
    }
  }
  a{
    color: #fff;
    &:hover{
      color: #3a8602;
    }
  }
}

.more{
  text-align: center;
  a{
    text-decoration: none;
    display: inline-block;
    font-size: rem(34);
    border: 1px solid #fff;
    padding: 5px 25px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(34);
      padding: vw(10) vw(25);
    }
  }
}



#consept
{
  padding-top:100px;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding-top: vw(100);
  }
  .contentLogo{
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(30);
    }
    img{
      width: 118px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(118);
      }
    }
  }
  h2{
    text-align: center;
    color: #000;
    font-size: rem(38);
    margin-bottom: 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(70);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
  .logoBg{
    position: relative;
    min-height: 720px;
    @media only screen and (max-width:$SmartphoneSize + px){
      min-height: vw(720);
    }
    span{
      color: #d70c19;
    }
    .box{
      text-align: center;
      padding-top: 75px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding-top: vw(75);
      }
      p{
        font-size: rem(34);
        line-height: 1.8;
        letter-spacing: 4px;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(34);
          letter-spacing: vw(4);
        }
        &.large{
          font-size: rem(64);
          font-weight: bold;
          margin-bottom: 75px;
          letter-spacing: 8px;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(64);
            margin-bottom: vw(75);
          }
        }
      }
    }
    .bg{
      opacity: .1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
  h3{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-top: vw(100);
      margin-bottom: vw(30);
    }
    span{
      padding: 10px 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
      font-size: rem(32);
      color: #fff;
      background: #000;
      line-height: 1.1;
      margin-bottom: 30px;
      display: inline-block;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding: vw(15) vw(50);
        font-size: vw(32);
        margin-bottom: vw(30);
      }
    }
  }
  .num{
    font-family: 'Montserrat', sans-serif;
    font-size: rem(120);
    text-align: center;
    font-weight: bold;
    color: #d70c19;
    line-height: .7;
    margin-bottom: 50px;
    letter-spacing: 8px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(120);
      margin: vw(50);
    }
  }
  .point{
    text-align: center;
    margin-bottom: 90px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(90);
      img{
        width: vw(600);
      }
    }
    h4{
      font-size: rem(48);
      text-align: center;
      border-bottom: 6px solid #000;
      display: inline-block;
      margin-bottom: 60px;
      line-height: 1.4;
      padding-bottom: 15px;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
        border-bottom: vw(6) solid #000;
        margin-bottom: vw(60);
        padding-bottom: vw(15);
      }
    }
    .text{
      text-align: left;
      padding: 0 50px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding: 0 vw(50);
      }
      p{
        margin-bottom: 80px;
        font-size: rem(32);
        letter-spacing: 2px;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(32);
          margin-bottom: vw(80);
        }
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
}

#promise{
  padding-bottom: 100px;
  img{
    width: 650px;
    @media only screen and (max-width:$SmartphoneSize + px){
      width: vw(650);
    }
  }
  .contentLogo{
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(30);
    }
    img{
      width: 118px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(118);
      }
    }
  }
  h2{
    text-align: center;
    color: #000;
    font-size: rem(38);
    margin-bottom: 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(70);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
}

#menu
{
  padding: 60px 50px;
  background: #000;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(60) vw(50);
  }
  .contentLogo{
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(30);
    }
    img{
      width: 118px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(118);
      }
    }
  }
  h2{
    text-align: center;
    color: #fff;
    font-size: rem(38);
    margin-bottom: 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(70);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
  h3{
    font-family: 'Noto Serif Japanese', serif;
    color: #fff;
    font-size: rem(54);
    text-align: center;
    margin-bottom: 60px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(54);
      margin-bottom: vw(54);
    }
  }
  .menuList
  {
    li{
      margin-bottom: 90px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-bottom: vw(90);
      }
      &:last-child{
        margin-bottom: 0;
      }
      h4{
        text-align: center;
        font-family: 'Noto Serif Japanese', serif;
        font-size: rem(58);
        color: #fff;
        line-height: 1.2;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(58);
        }
      }
      .price{
        color: #d70c19;
        text-align: center;
        font-size: rem(56);
        font-family: 'Noto Serif Japanese', serif;
        letter-spacing: 4px;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(56);
        }
        span{
          color: #fff;
          font-size: rem(36);
          line-height: 2.3;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(36);
          }
        }
      }
      .atention{
        letter-spacing: 3px;
        &.mid{
          font-size: rem(36);
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(36);
          }
        }
        font-size: rem(32);
        color: #fff;
        text-align: center;
        @media only screen and (max-width:$SmartphoneSize + px){
          font-size: vw(32);
        }
      }
    }
  }
  #menuimg{
    padding-top: 40px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-top: vw(40);
    }
    li{
      border-bottom: 2px solid #1b1b1b;
      padding-bottom: 40px;
      margin-bottom: 40px;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding-bottom: vw(40);
        margin-bottom: vw(40);
      }
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}

#submenu{
  padding: 45px 50px;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(45) vw(50);
  }
  background: #000;
  h2{
    color: #fff;
    font-family: 'Noto Serif Japanese', serif;
    text-align: center;
    font-size: rem(42);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(42);
    }
    span{
      display: block;
      font-family: 'Noto Sans Japanese', serif;
      font-size: rem(32);
      letter-spacing: 2px;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(32);
      }
    }
    border-bottom: 1px solid #444;
    padding-bottom: 30px;
    margin-bottom: 50px;
    @media only screen and (max-width:$SmartphoneSize + px){
      padding-bottom: vw(30);
      margin-bottom: vw(50);
    }
  }
  ul{
    li{
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      align-items: stretch;
      color: #fff;
      font-size: rem(28);
      letter-spacing: 4px;
      margin-bottom: 30px;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(28);
        margin-bottom: 30px;
      }
      p:first-child{
        line-height: 1.2;
        span{
          font-size: rem(24);
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(24);
          }
        }
      }
      p:last-child{
        color: #d70c19;
        span{
          color: #fff;
          font-size: rem(24);
          line-height: 1.8;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(24);
          }
        }
      }
    }
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap:wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    &.half{
      li{
        width: 45%;
      }
    }
  }
  .note{
    color: #fff;
    font-size: rem(26);
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(26);
    }
  }
}

#insta{
  padding-top: 100px;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding-top: vw(100);
  }
  .icon{
    text-align: center;
    margin-bottom: 40px;
    img{
      width: 100px;
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(40);
      img{
        width: vw(100);
      }
    }
  }
  h2{
    text-align: center;
    color: #000;
    font-size: rem(38);
    margin-bottom: 60px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(60);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
}

.instagram{
  padding: 1px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
  flex-wrap:wrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(1);
  }
  li{
    width: 248px;
    margin-right: 2px;
    margin-bottom: 2px;
    &:nth-child(3n)
    {
      margin-right: 0;
    }
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-right: 2px;
      margin-bottom: 2px;
      width: 32.3vw;
    }
    img{
      width: 100%;
    }
  }
}

#shopinfo{
  padding-top: 100px;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding-top: vw(100);
  }
  .contentLogo{
    text-align: center;
    margin-bottom: 30px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(30);
    }
    img{
      width: 118px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(118);
      }
    }
  }
  h2{
    text-align: center;
    color: #000;
    font-size: rem(38);
    margin-bottom: 70px;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(38);
      margin-bottom: vw(70);
    }
    span{
      font-size: rem(48);
      font-family: 'Montserrat', sans-serif;
      letter-spacing: 4px;
      display: block;
      @media only screen and (max-width:$SmartphoneSize + px){
        font-size: vw(48);
      }
    }
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 80px;
      height: 6px;
      background: #d70c19;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      margin: auto;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(80);
        height: vw(6);
        bottom: vw(-25);
      }
    }
  }
  dl{
    padding: 60px 50px;
    font-size: rem(32);
    @media only screen and (max-width:$SmartphoneSize + px){
      padding: vw(60) vw(50);
      font-size: vw(32);
    }
    div{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-flex-wrap: wrap;
      flex-wrap:wrap;
      -webkit-align-items: stretch;
      align-items: stretch;
      padding-bottom:35px;
      margin-bottom: 35px;
      border-bottom: 1px solid #ddd;
      @media only screen and (max-width:$SmartphoneSize + px){
        padding-bottom:vw(35);
        margin-bottom:vw(35);
      }
      dt{
        width: 30%;
        line-height: 1;
        font-weight: bold;
        span{
          display: block;
          width: 100%;
          position: relative;
          &::after{
            content: '：';
            position: absolute;
            right: 10px;
            @media only screen and (max-width:$SmartphoneSize + px){
              right: vw(10);
            }
          }
        }
      }
      dd{
        width: 70%;
        line-height: 1;
        a{
          color: #d70c19;
          text-decoration: none;
          font-size: rem(40);
          font-weight: bold;
          line-height: 0.7;
          @media only screen and (max-width:$SmartphoneSize + px){
            font-size: vw(40);
          }
        }
      }
    }
  }
  .map{
    iframe{
      width: 100%;
      height: 500px;
      @media only screen and (max-width:$SmartphoneSize + px){
        height: vw(500);
      }
    }
  }
}

footer{
  background: #000;
  padding: 50px 0;
  @media only screen and (max-width:$SmartphoneSize + px){
    padding: vw(50) 0;
  }
  text-align: center;
  .footerlogo
  {
    margin-bottom:50px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(50);
    }
    img{
      width: 118px;
      @media only screen and (max-width:$SmartphoneSize + px){
        width: vw(118);
      }
    }
  }
  ul{
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    flex-wrap:wrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    margin-bottom:60px;
    @media only screen and (max-width:$SmartphoneSize + px){
      margin-bottom: vw(60);
    }
    li{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-wrap: wrap;
      flex-wrap:wrap;
      -webkit-align-items: center;
      align-items: center;
      margin-right: 16px;
      @media only screen and (max-width:$SmartphoneSize + px){
        margin-right: vw(16);
      }
      img{
        width: 100%;
      }
      &:nth-child(1){
        width: 57px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(57);
        }
      }
      &:nth-child(2){
        width: 54px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(54);
        }
      }
      &:nth-child(3){
        width: 51px;
        @media only screen and (max-width:$SmartphoneSize + px){
          width: vw(51);
        }
        margin-right: 0;
      }
    }
  }
  .copy{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-size: rem(24);
    font-weight: bold;
    @media only screen and (max-width:$SmartphoneSize + px){
      font-size: vw(24);
    }
  }
}

.red{
  color: #d70c19;
}
.green{
  color: #3a8602;
}
